/* eslint-disable indent */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Card, Button } from 'Common'
// import starIcon from 'Static/icons/star.svg'
// import forkIcon from 'Static/icons/fork.svg'
import { Wrapper, Grid, Item, Content, Stats } from './styles'

export const Projects = () => {
  const {
    github: {
      repositoryOwner: {
        repositories: { edges },
      },
    },
  } = useStaticQuery(graphql`
    {
      github {
        repositoryOwner(login: "philemonmastewal") {
          repositories(
            first: 3
            orderBy: { field: STARGAZERS, direction: DESC }
          ) {
            edges {
              node {
                id
                name
                url
                homepageUrl
                description
                stargazers {
                  totalCount
                }
                forkCount
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {edges.map(({ node }) => (
          <Item
            key={node.id}
            as="a"
            // href={node.homepageUrl}
            // target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <Content>
                <h4>{node.name}</h4>
                <p>{node.description}</p>

                {/* <a>{node.homepageUrl}</a> */}
              </Content>
              <Button as="a" target="_blank" href={node.url}>
                GitHub
              </Button>
              <Button as="a" target="_blank" href={node.homepageUrl}>
                Demo
              </Button>

              {/* <Stats>
								<div>
									<img src={starIcon} alt="stars" />
									<span>{node.stargazers.totalCount}</span>
								</div>
								<div>
									<img src={forkIcon} alt="forks" />
									<span>{node.forkCount}</span>
								</div>
							</Stats> */}
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  )
}
